<template>
    <div>
        <div class="leadBox">
            <img src="../../assets/images/scan/iconlogin.png" alt="" class="iconImgs">
            <div class="titleMkc">
				<div>市场分析</div>
				<div style="font-size: 24px;width: 50%;margin: 20px auto;">市场分析APP</div>
				<div style="font-size: 16px;width: 60%;margin: 10px auto;">多维度统计消费者扫码数据、产品销售数据，辅助企业市场决策</div>
			</div>
			
            <div class="leadBtn" @click="clickBtn()">
                点击下载
            </div>
        </div>
        <div class="startMask" v-if="maskFlag" @click="maskFlag=false">
            <img src="../../assets/images/scan/arrowStart.png"  class="arrowImg" />
            <div class="tipsTitle">
                点击右上角，选择浏览器打开
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'index',
        data() {
            return {
                maskFlag: false
            }
        },
        created() {
            document.title = 'APP下载'
        },
        mounted() {
          document.title = 'APP下载'
        },
        methods: {
            clickBtn() {
                var isWx = this.is_weixn();
                var mywin = window.open('','_self');  //注意一定要在请求前操作此步
                if (isWx == true) {
                    this.maskFlag = true
                } else {
                    mywin.location.href = 'https://api.86122m.com/mkt/app/mkc.apk';
                    // window.open("http://mkt.xitangsoft.com/app/shhx.apk")
                }
            },
            is_weixn(){
                var ua = navigator.userAgent.toLowerCase();
                if(ua.match(/MicroMessenger/i)=="micromessenger") {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style scoped>
    .startMask{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.6);
    }
    .arrowImg{
        width: 50px;
        height: auto;
        position: absolute;
        right: 55px;
        top: 30px;
    }
    .tipsTitle{
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        position: absolute;
        right: 25px;
        top: 100px;
    }

    .leadBox{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: linear-gradient(to bottom, #5cb5fd, #fff);
        /*background: url(../../assets/images/scan/startBack.png) no-repeat;*/
        background-size: 100% 100%;
    }
    .iconImgs{
        width: 30%;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 20%;
        margin: auto;
    }
    .titleMkc{
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 40%;
    }
    .leadBtn{
        width: 60%;
        height: 50px;
        line-height: 50px;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15%;
        margin: auto;
        background: linear-gradient(to right, #5cb5fd, #1392fa);
        border-radius: 50px;
        text-align: center;
        font-size: 16px;
        letter-spacing: 3px;
    }
</style>

